<template>
    <div class='teacher-statistic-table'>
        <div class='tsmp-students-header bg-secondary text-white font-weight-bold'>
            <div class="column-sort" @click="sort('teacher_name')">
                Преподаватель
                <svg
                    width="15px"
                    height="15px"
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xml:space="preserve"
                    version="1.1"
                    id="Layer_1"
                    class="column-sort-arrow"
                    style="enable-background:new 0 0 512 512;"
                    :class="{ 'column-sort-arrow-active': orderBy === 'teacher_name', 'column-sort-arrow-active-rotate': orderBy === 'teacher_name' && sortDirection === 'DESC'}"
                >
                  <path d="M98.9,184.7l1.8,2.1l136,156.5c4.6,5.3,11.5,8.6,19.2,8.6c7.7,0,14.6-3.4,19.2-8.6L411,187.1l2.3-2.6  c1.7-2.5,2.7-5.5,2.7-8.7c0-8.7-7.4-15.8-16.6-15.8v0H112.6v0c-9.2,0-16.6,7.1-16.6,15.8C96,179.1,97.1,182.2,98.9,184.7z"/>
                </svg>
            </div>
            <div class='text-center column-sort' @click="sort('all_students')">
                Ученики
                <svg
                    width="15px"
                    height="15px"
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xml:space="preserve"
                    version="1.1"
                    id="Layer_1"
                    class="column-sort-arrow"
                    style="enable-background:new 0 0 512 512;"
                    :class="{ 'column-sort-arrow-active': orderBy === 'all_students', 'column-sort-arrow-active-rotate': orderBy === 'all_students' && sortDirection === 'DESC'}"
                >
                  <path d="M98.9,184.7l1.8,2.1l136,156.5c4.6,5.3,11.5,8.6,19.2,8.6c7.7,0,14.6-3.4,19.2-8.6L411,187.1l2.3-2.6  c1.7-2.5,2.7-5.5,2.7-8.7c0-8.7-7.4-15.8-16.6-15.8v0H112.6v0c-9.2,0-16.6,7.1-16.6,15.8C96,179.1,97.1,182.2,98.9,184.7z"/>
                </svg>
            </div>
            <div class='text-center column-sort' @click="sort('new_students')">
                Новые ученики
                <svg
                    width="15px"
                    height="15px"
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xml:space="preserve"
                    version="1.1"
                    id="Layer_1"
                    class="column-sort-arrow"
                    style="enable-background:new 0 0 512 512;"
                    :class="{ 'column-sort-arrow-active': orderBy === 'new_students', 'column-sort-arrow-active-rotate': orderBy === 'new_students' && sortDirection === 'DESC'}"
                >
                  <path d="M98.9,184.7l1.8,2.1l136,156.5c4.6,5.3,11.5,8.6,19.2,8.6c7.7,0,14.6-3.4,19.2-8.6L411,187.1l2.3-2.6  c1.7-2.5,2.7-5.5,2.7-8.7c0-8.7-7.4-15.8-16.6-15.8v0H112.6v0c-9.2,0-16.6,7.1-16.6,15.8C96,179.1,97.1,182.2,98.9,184.7z"/>
                </svg>
            </div>
            <div class='text-center column-sort' @click="sort('type_trial_lessons')">
                Пробные занятия
                <svg
                    width="15px"
                    height="15px"
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xml:space="preserve"
                    version="1.1"
                    id="Layer_1"
                    class="column-sort-arrow"
                    style="enable-background:new 0 0 512 512;"
                    :class="{ 'column-sort-arrow-active': orderBy === 'type_trial_lessons', 'column-sort-arrow-active-rotate': orderBy === 'type_trial_lessons' && sortDirection === 'DESC'}"
                >
                  <path d="M98.9,184.7l1.8,2.1l136,156.5c4.6,5.3,11.5,8.6,19.2,8.6c7.7,0,14.6-3.4,19.2-8.6L411,187.1l2.3-2.6  c1.7-2.5,2.7-5.5,2.7-8.7c0-8.7-7.4-15.8-16.6-15.8v0H112.6v0c-9.2,0-16.6,7.1-16.6,15.8C96,179.1,97.1,182.2,98.9,184.7z"/>
                </svg>
            </div>
            <div class='text-center column-sort' @click="sort('all_lessons')">
                Часов занятий
                <svg
                    width="15px"
                    height="15px"
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xml:space="preserve"
                    version="1.1"
                    id="Layer_1"
                    class="column-sort-arrow"
                    style="enable-background:new 0 0 512 512;"
                    :class="{ 'column-sort-arrow-active': orderBy === 'all_lessons', 'column-sort-arrow-active-rotate': orderBy === 'all_lessons' && sortDirection === 'DESC'}"
                >
                  <path d="M98.9,184.7l1.8,2.1l136,156.5c4.6,5.3,11.5,8.6,19.2,8.6c7.7,0,14.6-3.4,19.2-8.6L411,187.1l2.3-2.6  c1.7-2.5,2.7-5.5,2.7-8.7c0-8.7-7.4-15.8-16.6-15.8v0H112.6v0c-9.2,0-16.6,7.1-16.6,15.8C96,179.1,97.1,182.2,98.9,184.7z"/>
                </svg>
            </div>
            <div></div>
        </div>

        <div
            v-if='total && Object.keys(total).length'
            class='tsmp-students-body-row bg-light font-medium border-bottom border-gray'
        >
            <div class='tsmp-students-body-grid'>
                <div></div>
                <div class='text-center'>{{ total.all_students }}</div>
                <div class='text-center'>{{ total.new_students }}</div>
                <div class='text-center'>{{ total.type_trial_lessons }}</div>
                <div class='text-center'>{{ total.all_lessons }}</div>
                <div></div>
            </div>
        </div>

        <template v-if='data && data.length > 0'>
            <div
                v-for='(teacher, idx) in data'
                :key='teacher.id'
                class='tsmp-students-body-row border-bottom border-gray'
                :class="{ 'active': isStudentRowVisible(idx) }"
                @click='toggleStudentRowVisibility(idx, $event)'
            >
                <div class='tsmp-students-body-grid'>
                    <div>
                        {{ teacher.teacher_name }}
                    </div>
                    <div class='text-center'>{{ teacher.all_students }}</div>
                    <div class='text-center'>{{ teacher.new_students }}</div>
                    <div class='text-center'>{{ teacher.type_trial_lessons }}</div>
                    <div class='text-center'>{{ teacher.all_lessons }}</div>
                    <div class='text-center d-flex align-items-center'>
                        <a @click.prevent='() => false' class='tsmp-students-icon'>
                            <i class='fa fa-angle-down'></i>
                        </a>
                    </div>
                </div>

                <div
                    v-show='isStudentRowVisible(idx)'
                    :key='`tsmp-collapse-${teacher.id}-${idx}`'
                    class='tsmp-collapse w-100 bg-white border-gray'
                    @click.stop='() => false'
                >
                    <div v-auto-animate>
                        <div v-if='isStudentRowVisible(idx)' class='tsmp-collapse-row'>
                            <b-list-group>
                                <b-list-group-item>
                                    Запланированных: <b-badge class='tsmp-badge' variant='primary' pill>{{ teacher.status_planned_lessons }}</b-badge>
                                </b-list-group-item>

                                <b-list-group-item>
                                    Подтвержденных: <b-badge class='tsmp-badge' variant='primary' pill>{{ teacher.status_finished_lessons }}</b-badge>
                                </b-list-group-item>

                                <b-list-group-item>
                                    Пропущенных: <b-badge class='tsmp-badge' variant='primary' pill>{{ teacher.status_skipped_lessons }}</b-badge>
                                </b-list-group-item>

                                <b-list-group-item>
                                    Оплачено:
                                    <span class='tsmp-badge fs-5 text-center'>
                                    <i class='fa fa-check-circle' :class="teacher.paid ? 'text-success' : 'text-secondary opacity-50'"></i>
                                </span>
                                </b-list-group-item>
                            </b-list-group>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'TeacherStatisticTable',
    props: {
        data: {
            type: Array,
            required: true,
        },
        total: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            visibleStudentRows: [],
            sortDirection: 'ASC',
            orderBy: 'teacher_name',
        };
    },
    computed: {
        isStudentRowVisible() {
            return (idx) => this.visibleStudentRows.includes(idx);
        },
    },
    methods: {
        sort (field) {
            this.orderBy = field;

            this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';

            this.$emit('sort', {
                orderBy: this.orderBy,
                sortDirection: this.sortDirection
            })
        },
        toggleStudentRowVisibility(rowIdx) {
            if (this.isStudentRowVisible(rowIdx)) {
                this.visibleStudentRows = this.visibleStudentRows.filter(row => row !== rowIdx);
            } else {
                this.visibleStudentRows.push(rowIdx);
            }
        },
    },
};
</script>

<style lang='scss' scoped>
.tsmp-students-header,
.tsmp-students-body-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr) 142px;
    align-items: center;
}

.tsmp-students-header {
    font-weight: 600;
    text-transform: uppercase;

    .column-sort {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-content {
            display: flex;
            flex-flow: column;
            align-items: center;
        }

        &-arrow {
            fill: #000000;
            color: #000000;
        }

        &-arrow-active {
            fill: #FFFFFF;
            color: #FFFFFF;

            &-rotate {
                transform: rotate(180deg);
            }
        }
    }

    & > * {
        padding: 12px 0;

        &:first-child {
            padding-left: 20px;
        }
    }
}

.tsmp-students-body-row {
    position: relative;
    align-items: center;

    &:hover {
        cursor: pointer;
        transition: background-color .3s ease;
        background-color: rgba(#798892, 0.08);
    }

    &.active {
        background-color: rgba(#1B3C66, 0.1);

        .tsmp-students-icon {
            transform: rotateX(180deg);
        }
    }
}

.tsmp-students-body-grid {
    & > * {
        padding: 12px 0;

        &:first-child {
            padding-left: 20px;
        }

        &:last-child {
            padding-right: 20px;
        }
    }
}

.tsmp-students-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-decoration-line: none !important;
}

.tsmp-collapse {
    padding: 12px 0;
    cursor: default;

    .list-group {
        max-width: 324px;
    }

    .list-group-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 0;
    }
}

.tsmp-collapse .badge,
.tsmp-badge {
    min-width: 82px;
    padding: 6px 12px;
}
</style>