<template>
    <div class='teacher__details'>
        <Preloader v-if='loader' />
        <h2 class='mb-3'>Статистика преподавателей</h2>

        <div class='row mb-4'>
            <div class='col-5'>
                <VCalendar
                    class='teacher-salaries-section-calendar'
                    mode='date'
                    is-range
                    :popover="{ visibility: 'focus' }"
                    :masks="{ input: ['DD.MM.YYYY']}"
                    :value='date'
                    @input='handleDateSelect'
                    v-model='date'
                >
                    <template v-slot='{ togglePopover }'>
                        <div @click='togglePopover()'>
                            <input
                                class='form-control'
                                :class="{'hidden': !date.start}"
                                :value='`с ${formatDate(date.start)} по ${formatDate(date.end)}`'
                                readonly
                            />
                        </div>
                    </template>
                </VCalendar>
            </div>

            <div class='col-6'>
                <VueMultiselect
                    class='custom__multiselect'
                    :multiple='true'
                    :close-on-select='false'
                    :clear-on-select='false'
                    :preserve-search='true'
                    selectedLabel='выбран'
                    selectLabel='нажмите чтобы выбрать'
                    deselectLabel='нажмите чтобы убрать'
                    placeholder='Введите имя преподавателя'
                    :tagable='true'
                    tagPosition='bottom'
                    label='text'
                    track-by='value'
                    :options='teachersOptions'
                    v-model='selectedTeachers'
                    @input='selectTeacher'
                >
                    <template #noOptions>Нет данных. Попробуйте перезагрузить страницу</template>
                    <template #noResult>Ничего не найдено. Попробуйте изменить запрос</template>
                </VueMultiselect>
            </div>

            <div class='ml-auto align-self-center mr-4'>
                <b-button variant='outline-primary' type='button' title='Очистить фильтр' @click='clearFilters'>
                    <i class='fa fa-close'></i>
                </b-button>
            </div>
        </div>

        <TeacherStatisticTable v-if='stats && stats.length > 0' :data='stats' :total='total' :pagination="pagination" @sort="onSort"/>
        <div v-else>Нет результатов</div>

        <div v-if='pagination && pagination.current_page <= pagination.last_page' class='py-4 overflow-auto'>
          <b-pagination
              class='paginator'
              :total-rows='pagination.total'
              :per-page='pagination.per_page'
              @page-click='handlePageSelect'
              v-model="page"
          />
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import VueMultiselect from 'vue-multiselect';
import VCalendar from 'v-calendar/lib/components/date-picker.umd';
import TeacherStatisticTable from '@/components/admin/teacher-statistic/TeacherStatisticTable.vue';

const teachersOptionsAll = { value: -1, text: 'Все преподаватели' };

export default {
    name: 'TeacherStatistic',
    components: {
        VCalendar,
        VueMultiselect,
        TeacherStatisticTable,
        Preloader: () => import('@/components/Preloader'),
    },
    data() {
        return {
            loader: false,
            pagination: null,
            page: 1,
            date: {
                start: dayjs().subtract(1, 'month').set('date', 1).format('YYYY-MM-DD'),
                end: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
            },
            stats: null,
            total: {},
            teachersOptions: [],
            selectedTeachers: [],
            sortDirection: 'ASC',
            orderBy: 'teacher_name',
        };
    },
    async created() {
        await this.fetchTeacherOptions();
        await this.selectTeacher([teachersOptionsAll]);
    },
    methods: {
        onSort(data) {
            this.orderBy = data.orderBy;
            this.sortDirection = data.sortDirection;
            this.fetchData()
        },
        async handleDateSelect() {
            await this.fetchData();
        },
        formatDate(date) {
            return dayjs(date).format('DD.MM.YYYY');
        },
        async selectTeacher(teachers) {
            if (teachers.length > 0) {
                if (teachers.length > 1) {
                    this.selectedTeachers = teachers.filter(item => item.value !== -1);
                } else {
                    this.selectedTeachers = teachers;
                }
            } else {
                this.selectedTeachers = [teachersOptionsAll];
            }
            this.page = 1

            await this.fetchData();
        },
        async clearFilters() {
            this.selectedTeachers = [teachersOptionsAll];
            this.date = {
                start: dayjs().subtract(1, 'month').set('date', 1).format('YYYY-MM-DD'),
                end: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
            };

            await this.fetchData();
        },
        async handlePageSelect(bvEvent, page) {
            if (page) {
                // const query = Object.assign({}, this.$route.query, { page });
                // await this.$router.push({ query: query });
                this.page = page
                await this.fetchData()
            }

            return false;
        },
        async fetchData() {
            this.loader = true;
            try {
                const { data } = await this.$axios.post('/crm/teachers/stats/', {
                    teacher_ids: this.getTeacherIds(),
                    from: dayjs(this.date.start).format('DD.MM.YYYY'),
                    to: dayjs(this.date.end).format('DD.MM.YYYY'),
                    order_by: this.orderBy,
                    sort_direction: this.sortDirection,
                    page: this.page,
                });

                await this.fetchTotalStatistics()

                this.stats = data.data;
                this.pagination = data.meta;


            } catch (ex) {
                console.log('cant fetch teachers: ', ex);
            }

            this.loader = false;
        },
        async fetchTotalStatistics() {

            try {
                const { data } = await this.$axios.post('/crm/teachers/stats-total/', {
                    teacher_ids: this.getTeacherIds(),
                    from: dayjs(this.date.start).format('DD.MM.YYYY'),
                    to: dayjs(this.date.end).format('DD.MM.YYYY'),
                });

                this.total = data.data[0];
            } catch (ex) {
                console.log('cant fetch teachers: ', ex);
            }

        },
        async fetchTeacherOptions() {
            try {
                const { data } = await this.$axios.get('/api/teachers/short');

                this.teachersOptions = [
                    teachersOptionsAll,
                    ...data.items,
                ];

                console.log(this.teachersOptionsAll)
            } catch (ex) {
                console.log('cant fetch teacher options: ', ex);
            }
        },
        getTeacherIds() {
            let teacher_ids = [];

            if (this.selectedTeachers && this.selectedTeachers.length > 0) {
                for (let i = 0; i < this.selectedTeachers.length; i++) {
                    const item = this.selectedTeachers[i];

                    if (item.value === -1) {
                        teacher_ids = [];
                        break;
                    } else {
                        teacher_ids.push(item.value);
                    }
                }
            } else {
                teacher_ids = [];
            }

            return teacher_ids;
        },
    },
};
</script>

<style lang='scss' scoped>
::v-deep {
    .form__group {
        justify-content: flex-end;

        label {
            margin: 0;
        }
    }
}

.teacher-salaries-section-calendar {
    min-width: 328px;

    .form-control {
        padding: 10px 1rem;
        height: auto;
    }
}

.clear-input {
    position: absolute;
    right: 10px;
    color: lighten(red, 20);
    top: 50%;
    transform: translateY(-50%);
}
</style>